import React, {useContext, useEffect, useState} from "react"
import GoogleRbox from "../../googleReviews/GoogleRbox"
import PropTypes from "prop-types"
import Image from "next/image"
import NewMegaMenu from "./NewMegaMenu"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import {content, tabItems} from "../../../constants/Instances"
import fetchReviews from "../../../fetch/FetchReviews"
import Typography from "../../reusable/Typography/Typography"
import DynamicFormGenerator from "../../forms/DynamicFormGenerator"
import {useTranslation} from "next-i18next"
import OrganizationContext from "../../../context/OrganizationContext"

function EcoSystemTabs() {
	const [isToggleOpen, setIsToggleOpen] = useState(false)
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const [activeItem, setActiveItem] = useState(null)
	const [reviewArray, setReviewArray] = useState(null)
	const [isModalShown, setIsModalShown] = useState(false)
	const [modalTabIndex, setModalTabIndex] = useState(null)
	const {organizationSlug} = useContext(OrganizationContext)

	useEffect(() => {
		fetchReviews().then(data => setReviewArray(data))
	}, [])

	const handleMouseEnter = itemTitle => {
		setIsBackgroundOn(true)
		setIsToggleOpen(true)
		setActiveItem(itemTitle)
	}

	const handleMouseLeave = () => {
		setIsToggleOpen(false)
		setActiveItem(null)
	}

	const {t} = useTranslation("common")
	return (
		<div className="bg-grey" style={{width: "100%", height: "2.2rem"}}>
			<div className="d-flex align-items-start justify-content-between " style={{maxWidth: 1790, margin: "0 auto", padding: "0 64px", zIndex: 999}}
				onMouseLeave={handleMouseLeave}
			>
				<ul className="d-flex list-unstyled align-items-center justify-content-center h-100 mb-0" >
					{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && <li className={"d-flex align-items-center justify-content-center "} style={{marginRight: "2px", width: "auto", whiteSpace: "nowrap"}}>
						<Typography variant="bodyLgBlack" style={{fontSize: "1.1rem", marginRight: "5px"}}>MyPlan <span style={{fontFamily: "bold"}}>+</span> </Typography>
						<div style={{
							width: "auto"
						}}>
							<img alt={content[organizationSlug]?.companyName} src={content[organizationSlug]?.tabIcon} height={26} width={"auto"}/>
						</div>
					</li>}
					{tabItems?.map(({href, iconSrc, title, primaryColor, megaTitle, megaMenuTopBrands, megaMenuTopCategories, heights, ComingSoon, newsletterFormData, description}, index) => (
						<div
							onMouseLeave={() => megaTitle ? handleMouseLeave() : ""}
							key={index}
							className="position-relative pointer p-0 text-bold"
							style={{height: "100%"}}
						><li
								onMouseEnter={() => {
									if (!ComingSoon) {
										handleMouseEnter(title)
									}
								}}
								onClick={() => {
									if (ComingSoon) {
										setIsModalShown(true)
										setModalTabIndex(index)
									}
								}}
								onMouseLeave={() => megaTitle ? "" : handleMouseLeave()}
							>
								<a href={ComingSoon ? null : href}
									style={{height: "2.2rem"}}
									className={` ${ComingSoon && !isModalShown ? "custom-opacity" : isModalShown && "custom-opacity-active"}  px-1 d-flex align-items-center justify-content-center tab-link ${process.env.NEXT_PUBLIC_STOREFRONT === title.toLowerCase() ? "tab-link-active" : ""} ${activeItem === title ? "tab-link-hover" : ""}`}
								>
									<img alt={title} src={iconSrc} height={14} width={140} style={{marginTop: "0.4rem", height: "100%", padding: "4px 6px"}}/>
								</a>
							</li>
							<style jsx>{`
                    //This is just a way to define css styles inline but also possible to pass dynamic variables to it
                    .tab-link-active{
                        font-weight: 700;
                        background: white;
                    }
					 .custom-opacity {
     				 opacity: 0.5; /* Set custom opacity (0.5) */
					 transform: all 0.5s linear;
    					}
                    .tab-link {
                        font-weight: 500;
                        //color: #000000;
                        text-decoration: none;
                        border-bottom: 3px solid #fec04d00;
                        transition: border-color 250ms ease-in-out;
                    }
                    .tab-link-hover  {
                        color: #ffffff;
                        background: white;
                        border-bottom: 3px solid ${primaryColor};
                        transition: border-color 250ms ease-in-out;
                    }
					.tab-link:hover  {
                        color: #ffffff;
                        background: white;
						opacity: 1;
                        border-bottom: 3px solid ${primaryColor};
                        transition: border-color 250ms ease-in-out;
                    }

                    `}</style>
							<div
								style={{left: index < 3 ? 0 : index > 4 ? -230 : -100, zIndex: 99999999}}
								className="overflow-hidden position-absolute"
							>
								{megaTitle && <AccordionAnimation
									inProp={isToggleOpen && title === activeItem}
									duration={200}
									transTimingFn="ease-out"
									onExited={() => setIsBackgroundOn(false)}
									unmount
								>
									<NewMegaMenu toggleMenu={handleMouseLeave} themeColor={primaryColor} heights={heights} megaMenuTopCategories={megaMenuTopCategories} otherInstancesBrandsForMegaMenu={megaMenuTopBrands} item={{title: megaTitle}}/>
								</AccordionAnimation>}
							</div>

							{isModalShown && index === modalTabIndex &&
							<>
								<div onClick={() => setIsModalShown(false)} style={{width: "100vw", height: "100vh", top: 0, left: 0, right: 0, position: "fixed", background: "black", zIndex: 999997, opacity: 0.3}} />
								<div className={"position-absolute px-3 py-4 bg-white fade-in"} style={{
									zIndex: 999999,
									borderRadius: "16px",
									border: "1px solid #b3b3b3",
									minWidth: "36rem"
								}}>
									<div className="row mb-3  justify-content-between align-items-center">
										<div className="col-12 cursor-pointer text-end" onClick={() => setIsModalShown(prev => !prev)}>
											<Image src="/assets/icons/close-icon.svg" width="20" height="20"/>
										</div>
									</div>
									<Typography className="col-12 mb-3" variant="heading1Black" style={{lineHeight: "28px"}}>{title} - <span style={{color: primaryColor}}> Coming soon.</span></Typography>
									<Typography className="col-12 mb-3" variant="bodySm" style={{lineHeight: "16px"}}>{description}</Typography>
									<DynamicFormGenerator formData={newsletterFormData} t={t} theme={primaryColor}/>
								</div></>}
						</div>))}

				</ul>
				{reviewArray ? <div style={{marginTop: "-4px"}}> <GoogleRbox reviews={reviewArray} /> </div> : null}
			</div>
		</div>

	)
}

export default EcoSystemTabs

EcoSystemTabs.prototype = {
	reviews: PropTypes.array
}

