import React, {useState} from "react"
import PropTypes from "prop-types"
import {XLg} from "react-bootstrap-icons"
import Link from "next/link"
import CartButton from "../CartButton"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"
import {MenuTitlesArray} from "../../../utility/Helper"
import NewMobileMegaMenu from "./NewMobileMegaMenu"
import {useTranslation} from "next-i18next"
import StickyBar from "../StickyBar"
import SearchDesktop from "../../search/SearchDesktop"
import SearchResults from "../../search/SearchResults"
import Image from "next/image"
import BookAppointmentButton from "../../reusable/ModalButtons/BookAppointmentButton"
import BFESubHeader from "../desktop/BFESubHeader"
import WishListButton from "../WishListButton"

type Props = {
	absolute: boolean
	menuTitles: MenuTitlesArray[]
	stickyTop?: boolean
}

const MobileNavBar: React.FC<Props> = ({absolute, menuTitles, stickyTop}: Props) => {
	const [isMenuVisible, setIsMenuVisible] = useState(false)
	const [openAccordionName, setOpenAccordionName] = React.useState(null)
	const {t} = useTranslation()
	const toggleMenu = e => {
		e.stopPropagation()
		setOpenAccordionName(null)
		setIsMenuVisible(prev => !prev)
	}

	return (
		<div
			style={{zIndex: 100000, borderRadius: isMenuVisible ? "" : "0px 0px 16px 16px !important"}}
			className={`${stickyTop ? "sticky-top" : ""} d-lg-none py-0 shadow-sm bg-white overflow-auto`}
		>
			<AnimationComponent type="slideRight" inProp={isMenuVisible}>
				<div
					className={"position-fixed vh-100 w-100 border-top overflow-auto"}
					style={{zIndex: 99999, top: 147}}
					onClick={toggleMenu}>
					<NewMobileMegaMenu setIsMenuVisible={setIsMenuVisible}/>
				</div>
			</AnimationComponent>
			{process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE === "mhp_bfe" && <BFESubHeader/>}
			<StickyBar hasDiscountForNewsletterModal/>
			<div
				style={{zIndex: 999}} className="py-2 px-3">
				<div className={"d-flex justify-content-between align-items-center"}>
					<div className="d-flex align-items-center" style={{columnGap: 16, minHeight: 40}}>
						<Link href={"/"} as={"/"}>
							<a>
								<Image
									className="pointer main-logo"
									width={144}
									height={21}
									objectFit={"contain"}
									src="/assets/MyHomePlan-logo.svg"
									alt="MyHomePlan logo"
								/>
							</a>
						</Link>
					</div>
					<div className={"d-flex align-items-center"} style={{columnGap: 8}}>
						<BookAppointmentButton onlyIcon />
						<WishListButton />
						<CartButton isFrontpage={false} mobile={true}/>
						<div
							onClick={() => setIsMenuVisible(prev => !prev)} className="d-flex align-items-center pe-0 cursor-pointer">
							{isMenuVisible ? (
								<XLg color={"#000"} size={24}/>
							) : (
								<Image src="/assets/icons/burger-menu.svg" width="24px" height="24px" alt="burger menu" className="cursor-pointer" onClick={toggleMenu} />
							)}
						</div>

					</div>
				</div>
			</div>

			<div style={{borderRadius: "0px 0px 16px 16px"}} className="container d-md-none pt-2 pb-2 px-3">
				<div className="row justify-content-center">
					<div className="col-12 col-lg-8">
						<SearchDesktop/>
						<SearchResults/>
					</div>
				</div>
			</div>
		</div>
	)
}

MobileNavBar.propTypes = {
	absolute: PropTypes.bool.isRequired
}

export default MobileNavBar

